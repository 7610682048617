<template>
  <fade-transition>
    <div
      v-if="open"
      class="modal flex fixed pin items-center"
      @click.self="closeModal">
      <div
        :class="[
          modalSizeClass,
          { 'anchor-to-top': anchorToTop, 'edge-to-edge': edgeToEdge }
        ]"
        class="modal-dialog">
        <div
          v-if="$slots.upperImage"
          class="upper-image">
          <slot name="upperImage"></slot>
        </div>
        <div
          :class="[
            { 'flex flex-col': size === 'fullscreen' },
            modalContentClasses
          ]"
          class="modal-content">
          <overlay
            v-if="loading"
            absolutely-positioned />
          <div
            v-if="$slots.header"
            :class="headerClass"
            data-cy="modal-heading">
            <slot name="header"></slot>
            <div
              v-if="!requiresInteraction"
              :class="modalCloseClass"
              data-cy="close-the-modal"
              @click="$emit('closed')">
              <slot name="close-icon">
                <svg-icon
                  name="close-display"
                  width="24px"
                  height="24px" />
              </slot>
            </div>
          </div>
          <div
            v-else-if="!requiresInteraction"
            :class="modalCloseClass"
            data-cy="close-the-modal"
            @click="$emit('closed')">
            <slot name="close-icon">
              <svg-icon
                name="close-display"
                width="24px"
                height="24px" />
            </slot>
          </div>

          <div
            :class="modalBodyClasses"
            class="modal-body">
            <slot name="body"></slot>
          </div>

          <div
            v-if="$slots.footer"
            :class="[modalFooterClasses, footerClass]">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </fade-transition>
</template>

<script>
import FadeTransition from "../transitions/FadeTransition.vue";
import Overlay from "./Overlay.vue";
import SvgIcon from "./SvgIcon.vue";

export default {
  name: "Modal",
  components: {
    FadeTransition,
    Overlay,
    SvgIcon
  },
  props: {
    size: {
      type: String,
      default: ""
    },
    open: {
      type: Boolean,
      default: false
    },
    requiresInteraction: {
      type: Boolean,
      default: false
    },
    preventBackgroundClose: {
      type: Boolean,
      default: false
    },
    headerClass: {
      type: String,
      default: "modal-header"
    },
    footerClass: {
      type: String,
      default: "modal-footer"
    },
    modalCloseClass: {
      type: String,
      default: "modal-close"
    },
    anchorToTop: {
      type: Boolean,
      default: false
    },
    constrainHeight: {
      type: Boolean,
      default: false
    },
    modalContentClasses: {
      type: String,
      default: null
    },
    modalBodyClasses: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    edgeToEdge: {
      type: Boolean,
      default: false
    },
    fullScreen: {
      type: Boolean,
      default: false
    },
    preventBackgroundScroll: {
      type: Boolean,
      default: false
    }
  },
  emits: ["closed"],
  computed: {
    modalSizeClass() {
      return {
        "modal-sm": this.size === "small",
        "modal-lg": this.size === "large",
        "modal-xl": this.size === "xlarge",
        "modal-full": this.size === "fullscreen"
      };
    },
    modalFooterClasses() {
      return this.constrainHeight
        ? "absolute right-0 bottom-0 left-0 border-t border-ink-1 bg-paper-2"
        : null;
    }
  },
  watch: {
    open(value) {
      if (value && this.preventBackgroundScroll) {
        this.preventScrolling();
      } else {
        this.allowScrolling();
      }
    }
  },
  methods: {
    closeModal() {
      if (!this.requiresInteraction && !this.preventBackgroundClose) {
        this.$emit("closed");
      }
    },
    preventScrolling() {
      document.documentElement.classList.add("no-scroll");
      document.body.classList.add("no-scroll");
    },
    allowScrolling() {
      document.documentElement.classList.remove("no-scroll");
      document.body.classList.remove("no-scroll");
    }
  }
};
</script>

<style scoped>
.anchor-to-top {
  margin-top: 0;
}
</style>
